import React, { useState, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainRoutes from "./Routes/MainRoutes";
import useVerifyUser from "./Helpers/custom-hooks/useVerifyUser";
import { useSelector } from "react-redux";
import PageLoader from "./Components/Loaders/PageLoader";
import { PrivateRoutes } from "./Routes/PrivateRoutes";
import { Toaster } from "react-hot-toast";
import NetworkStatusPopup from "./Components/Network/NetworkStatusPopup";
import CustomerInvoicePage from "./Pages/CustomerInvoicePage/CustomerInvoicePage";
import Footer from "./Components/Footer/Footer";

const Sidebar = lazy(() => import("./Components/Sidebar/Sidebar"));
const Header = lazy(() => import("./Components/Header/Header"));
const Login = lazy(() => import("./Pages/Auth/Login"));
const Profile = lazy(() => import("./Pages/Profile/Profile"));
const Home = lazy(() => import("./Pages/Home/Home"));
const Customer = lazy(() => import("./Pages/Customer/Customer"));
const CustomerDetails = lazy(() =>
  import("./Pages/Customer/CustomerDetails/CustomerDetails")
);
const Payments = lazy(() => import("./Pages/Payments/Payments"));
const PaymentDetails = lazy(() =>
  import("./Pages/Payments/PaymentDetails/PaymentDetails")
);
const InvoiceComponent = lazy(() => import("./Pages/Invoice/InvoiceComponent"));
const AnalyticsComponent = lazy(() => import("./Pages/Analytics/Analytics"));

const App = () => {
  const [mainLoader, setmainLoader] = useState(true);
  const user = useSelector((state) => state.user);
  const refreshToken = localStorage.getItem("bstoken****");
  // Custom hook for verify user
  useVerifyUser(refreshToken, setmainLoader);

  if (mainLoader) return <PageLoader />;
  return (
    <div className="wrapper">
      {user && <Sidebar />}
      <div className="wrapper-main">
        {user && <Header />}
      
        <Suspense fallback={<PageLoader />}>
          <Routes>
          <Route
              path={MainRoutes.CUSTOMERINVOICEDETAILS}
              element={<CustomerInvoicePage />}
            />
            <Route
              path={MainRoutes.LOGIN}
              element={user ? <Navigate to={MainRoutes.HOME} /> : <Login />}
            />
            <Route element={<PrivateRoutes user={user} />}>
              <Route path="*" element={<Customer />} />
              <Route path={MainRoutes.PROFILE} element={<Profile />} />
              <Route path={MainRoutes.CUSTOMER} element={<Customer />} />
              <Route
                path={MainRoutes.CUSTOMERDETAILS}
                element={<CustomerDetails />}
              />
              <Route path={MainRoutes.PAYMENTS} element={<Payments />} />
              <Route
                path={MainRoutes.PAYMENTDETAILS}
                element={<PaymentDetails />}
              />
              <Route
                path={MainRoutes.INVOICEDETAILS}
                element={<InvoiceComponent />}
              />
                <Route
                path={MainRoutes.OVERALL}
                element={<AnalyticsComponent />}
              />
            </Route>
          </Routes>
        </Suspense>
        <Footer />
        <Toaster
          position="top-center"
          reverseOrder={false}
          //  gutter={3}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
          }}
        />
      </div>
    </div>
  );
};

export default App;
