const customerPrefix = "customer";
const managementPrefix = "management";
const paymentPrefix = "payment";



// ** CUSTOMER RELATED ENDPOINTS **
export const customerEndPoints = {
  addCustomer: customerPrefix + "/add-customer",
  getCustomers: customerPrefix + "/get-customers",
  getCustomersDetails: customerPrefix + "/get-customer-by-id",
  editCustomer: customerPrefix + "/edit-customer",
};

// ** PAYMENT RELATED ENDPOINTS **
export const paymentEndPoints = {
  addPayment: paymentPrefix + "/add-payment",
  getPayments: paymentPrefix + "/get-payments",
  getPaymentDetails: paymentPrefix + "/get-payment-by-id",
  editPayment: paymentPrefix + "/edit-payment",
  deletePayment: paymentPrefix + "/delete-payment",
  overallPayment: paymentPrefix + "/total-amount",
};


// ** AUTH RELATED ENDPOINTS **
export const AuthEndPoints = {
  managementLogin: managementPrefix + "/management-login",
  managementLogout: managementPrefix + "/management-logout",
  verifyUser: managementPrefix + "/verify-user",
  editManagement: managementPrefix + "/edit-management"

};

// ** MANAGEMENT RELATED ENDPOINTS **
export const ManagementEndPoints = {
  editManagement: managementPrefix + "/edit-management"

};



