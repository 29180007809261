import { useSelector } from "react-redux";
import ApiCall from "../Api/ApiCall";
import toast from "react-hot-toast";
import { LoadingToasterMessages } from "./toasterMessage";
import { AuthEndPoints } from "../Api/Endpoints";
import MainRoutes from "../Routes/MainRoutes";
import moment from "moment";
import { AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY } from "./aws";
import AWS from "aws-sdk";
import imageCompression from "browser-image-compression";

//******* START API CALL ********/
export const startApiCall = (seterrorMessage, setLoader) => {
  {
    seterrorMessage !== null && seterrorMessage("");
  }
  setLoader(true);
  setTimeout(() => {
    setLoader(false);
  }, 50000);
};

export const userLogout = async () => {
  const refreshToken = localStorage.getItem("bstoken****");
  if (refreshToken) {
    let data = { token: refreshToken };
    const res = await ApiCall("post", AuthEndPoints.managementLogout, data);
    if (res.success) {
      toast.success(LoadingToasterMessages.LOGOUT);
      localStorage.clear();
      window.location.href = MainRoutes.HOME;
    }
  } else {
    localStorage.clear();
    toast.success(LoadingToasterMessages.LOGOUT);
    window.location.href = MainRoutes.HOME;
  }
};

//********* DATE FORMATER ***********/
export const dateFormatter = (date) => {
  return moment(new Date(date)).format("DD/MM/YYYY");
};
//********* DATE FORMATER ***********/
export const dateTimeFormatter = (date) => {
  return moment(new Date(date)).format("DD/MM/YYYY - hh:mm:ss A");
};
//********* GET CURRENT DATE ***********/
export const getCurrentDate = () => {
  return moment(new Date()).format("YYYY-MM-DD");
};

//********* COPY TEXT ***********/
export const handleCopyClick = (complaintNumber) => {
  navigator.clipboard.writeText(complaintNumber).then(() => {
    toast.success("Text copied to clipboard");
  });
};

//********* DATE WISE MONTHLY VALUE ***********/

export const getMonthDateValue = (type) => {
  let startDate, endDate;
  if (type === "currentMonth") {
    startDate = moment().startOf("month").format("YYYY/MM/DD");
    endDate = moment().endOf("month").format("YYYY/MM/DD");
  } else if (type === "lastMonth") {
    startDate = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY/MM/DD");
    endDate = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY/MM/DD");
  } else {
    startDate = null;
    endDate = null;
  }

  return { startDate, endDate };
};

//*******UPLOAD IMAGE INTO AWS*****
// export const handleUploadInDirectAws = async (file) => {
//   AWS.config.update({
//     region: "ap-south-1",
//     credentials: new AWS.Credentials(AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY),
//   });

//   let s3 = new AWS.S3({
//     apiVersion: "2012-10-17",
//     params: { Bucket: "bs-fitness-bucket" },
//   });

//   let data = file?.target?.files[0];
//   let upload_params = {
//     Bucket: "bs-fitness-bucket",
//     Key: data ? data.name : "",
//     Body: data ? data : "",
//   };
//   return s3.upload(upload_params).promise();
// };

export const handleUploadInDirectAws = async (file) => {
  AWS.config.update({
    region: "ap-south-1",
    credentials: new AWS.Credentials(AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY),
  });

  let s3 = new AWS.S3({
    apiVersion: "2012-10-17",
    params: { Bucket: "bs-fitness-bucket" },
  });

  let data = file?.target?.files[0];

  // Compress the image
  const options = {
    maxSizeMB: 0.02, // Maximum size in MB (adjust based on the size of the original file)
    maxWidthOrHeight: 800, // Adjust to ensure a good balance of dimensions and file size
    useWebWorker: true, // Use web worker for compression
    maxQuality: 0.9 // Quality setting to achieve around 80% size reduction
  };

  try {
    const compressedFile = await imageCompression(data, options);

    let upload_params = {
      Bucket: "bs-fitness-bucket",
      Key: compressedFile.name,
      Body: compressedFile,
    };

    const result = await s3.upload(upload_params).promise();

    return result;
  } catch (error) {
    console.error("Error compressing or uploading the image", error);
    throw error;
  }
};
export const handleFileUploadInDirectAws = async (fileBlob, fileName) => {
  AWS.config.update({
    region: "ap-south-1",
    credentials: new AWS.Credentials(AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY),
  });
  const s3 = new AWS.S3({
    apiVersion: "2006-03-01",
    params: { Bucket: "bs-fitness-bucket" },
  });

  const uploadParams = {
    Bucket: "bs-fitness-bucket",
    Key: fileName,
    Body: fileBlob,
    ContentType: "application/pdf", // Ensure the content type is set to PDF
  };

  try {
    const result = await s3.upload(uploadParams).promise();
    return result;
  } catch (error) {
    console.error("Error uploading the file to AWS S3", error);
    throw error;
  }
};

export const handlePreviewFileInLocal = (event) => {
  if (event?.target?.files && event.target.files.length > 0) {
    let img = URL.createObjectURL(event.target.files[0]);
    return img;
  }
};

export const getAllMonths = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months;
};

export const getMonthDateRange = (month) => {
  const year = moment().year(); // Get the current year
  // Find the index of the month
  const monthIndex = getAllMonths()?.indexOf(month);

  // if (monthIndex === -1) {
  //   throw new Error('Invalid month name');
  // }
  // Create start and end dates for the given month
  const startDate = moment()
    .year(year)
    .month(monthIndex)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .year(year)
    .month(monthIndex)
    .endOf("month")
    .format("YYYY-MM-DD");

  return {
    startDate: month ? startDate : "",
    endDate: month ? endDate : "",
  };
};

export const getCurrentMonthName = () => {
  return moment().format("MMMM"); // 'MMMM' gives the full month name
};

export const getAdminName = (user, admin) => {
  return user?._id === admin?._id ? "You" : user?.name;
};

export const truncateNote = (note) => {
  if (note.length > 20) {
    return {
      truncated: note.slice(0, 20) + "...",
      isTruncated: true,
    };
  }
  return { truncated: note, isTruncated: false };
};

export const generateInvoiceNumber = (input) => {
  return input?.slice(-5);
};

export const getPageLimit = () => {
return localStorage.getItem("pageLimit") || 50
};