import React from "react";

const Footer = () => {
  return (
    <div className="fixed-bottom bg-white px-3 border-top d-flex align-items-center justify-content-end gap-5 h-30p w-100 overflow-hidden">
      <p className="fs-sm-2 text-secondary text-nowrap footer-text-animation w-100">
        For any business software inquiries, Call Us Today! 📞&nbsp;
        <span className="text-dark fw-bold">8918569670</span>&nbsp;and make your
        business easier and more efficient!
      </p>
    </div>
  );
};

export default Footer;
