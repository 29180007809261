import React from "react";

const PageLoader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 flex-column">
      <div className="dot-spinner">
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
      </div>
      <p className="mt-2">Loading ...</p>
    </div>
  );
};

export default PageLoader;
