let host = window.location.href;
let isProduction = host?.includes("bsfitness") ? true : true;
console.log(
  isProduction
    ? "🚨🚨🚨🚨🚨🚨LIVE🚨🚨🚨🚨🚨🚨"
    : "🚧🚧🚧🚧🚧DEVELOPMENT🚧🚧🚧🚧🚧🚧"
);

// ********* BASE URL ********
export const BASE_URL = isProduction?"https://api.bsfitness.in/api":"http://localhost:8005/api"

// ********* BASE URL ********
export const QR_URL = isProduction?"https://bsfitness.in":"http://localhost:3000"