const MainRoutes = {
  HOME: "/",
  LOGIN: "/login",
  PROFILE: "/profile",
  CUSTOMER: "/member",
  CUSTOMERDETAILS: "/member/:customerId",
  PAYMENTS: "/payment",
  PAYMENTDETAILS: "/payment/:paymentId",
  INVOICE: "/invoice",
  INVOICEDETAILS: "/invoice/:paymentId",
  CUSTOMERINVOICE: "/customer/invoice",
  CUSTOMERINVOICEDETAILS: "/customer/invoice/:paymentId",
  OVERALL:"/overall"
};

export default MainRoutes;
