export const SuccessToasterMessages = {
  LOGIN: "Logged in Succesfully!",
  USERVERIFIED: "User Verified Succesfully!",
  LOGOUT:"Logged Out Successfully!",
  VERIFYEMAIL:"Email Verified Successfully!",
  VERIFYOTP:"OTP Verified Successfully!",
  PASSWORDRESET:"Password Reset Successfully!",
  ADDMEMBER:"Add Member Successfully!",
  ADDPAYMENT:"Add Payment Successfully!",
  UPDATINGPROFILE:"Admin Profile Updating..",
  CUSTOMERUPDATE:"Member Profile Updated!",
  PAYMENTUPDATE:"Payment Update Successfully!",
  PDFDOWNLOAD:"PDF downloading...",
  PAYMENTDELETE : "Payment Deleted Successfully!"
};

export const LoadingToasterMessages = {
  LOGIN: "Logging In",
  LOGOUT: "Logging Out",
  USERVERIFY: "Verifying User",
};
