import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../../Assets/Logo/logo.jpg";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { paymentEndPoints } from "../../Api/Endpoints";
import ApiCall from "../../Api/ApiCall";
import {
  dateFormatter,
  dateTimeFormatter,
  generateInvoiceNumber,
  startApiCall,
} from "../../Helpers/globalFunctions";
import { SuccessToasterMessages } from "../../Helpers/toasterMessage";
import toast from "react-hot-toast";

const CustomerInvoicePage = () => {
  const user = useSelector((state) => state.user);
  const [invoiceData, setinvoiceData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const { paymentId } = useParams();

  const generatePDF = () => {
    const input = document.querySelector("#receipt-main");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      // Use a higher scale for better quality
      const imgData = canvas.toDataURL("image/jpeg", 0.9); // Moderate compression

      const pdf = new jsPDF("p", "mm", "a4", true); // Enable compression

      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(
        imgData,
        "JPEG",
        0,
        position,
        imgWidth,
        imgHeight,
        undefined,
        "FAST"
      ); // Using FAST compression
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "JPEG",
          0,
          position,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        );
        heightLeft -= pageHeight;
      }

      pdf
        .save(
          `BS-Invoice(${invoiceData?.customer?.name}-${invoiceData?.customer?.phone}).pdf`,
          { returnPromise: true }
        )
        .then(() => {
          toast.loading(SuccessToasterMessages.PDFDOWNLOAD);
        });
    });
  };

  const handleGetPaymentDetails = async () => {
    startApiCall(seterrorMessage, setLoader);
    const res = await ApiCall("get", paymentEndPoints.getPaymentDetails, {
      params: { paymentId },
    });
    if (res?.success) {
      setinvoiceData(res?.result);
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetPaymentDetails();
  }, []);

  return (
    <div id="invoice" className="d-flex flex-column px-3">
      {!loader && invoiceData && (
        <>
          <div className="d-flex align-items-center justify-content-end py-3">
            <div className="d-flex align-items-center">
              <button
                className="btn btn-primary-theme d-flex align-items-center gap-1 text-nowrap"
                onClick={generatePDF}
              >
                <i className="lni lni-download"></i>
                <span className="d-none-sm">Download invoice</span>
              </button>
            </div>
          </div>
          <div id="receipt-main" className="invoice-body">
            <div className="invoice-brand-section">
              <div className="invoice-brand-details">
                <h1 className="invoice-brand">BS FITNESS FACTORY GYM</h1>
                <h3 className="invoice-number">
                  INVOICE # BS-{generateInvoiceNumber(invoiceData?._id)}
                </h3>
                <p className="fs-sm">
                  {dateTimeFormatter(invoiceData?.createdAt)}
                </p>
              </div>
              <img
                className="invoice-logo"
                loading="lazy"
                alt="bsfitness-logo"
                src={logo}
              />
            </div>
            <div className="row invoice-details flex-col-reverse-md g-3">
              <div className="col-md-6 customer-details">
                <h5>Member Details</h5>
                <p>
                  Name:&nbsp;
                  <span className="text-capitalize">
                    {invoiceData?.customer?.name}
                  </span>
                </p>
                {invoiceData?.customer?.phone && (
                  <p>
                    Mobile:&nbsp;
                    <span>&nbsp;{invoiceData?.customer?.phone}</span>
                  </p>
                )}
                <p>
                  Address:&nbsp;
                  <span className="text-capitalize">
                    {invoiceData?.customer?.address || "NA"}
                  </span>
                </p>
              </div>
              <div className="col-md-6 contact-details text-start-sm">
                <p className="fs-sm-2">
                  Contact:&nbsp;
                  <span className="fw-bold">
                    9932731059, 977573436, 9563649023
                  </span>
                </p>
                <div className="d-flex justify-content-start-sm">
                  <p className="fs-sm-2">
                    Kunor more (Near Honda Showroom),
                    <br />
                    Kaliyaganj, WB, 733129
                  </p>
                </div>
              </div>
            </div>
            <div className="d-block-md-768p">
              <div className="payment-details-table">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th className="p-responsive">Payment Date</th>
                      <td className="p-responsive">
                        {dateFormatter(invoiceData?.paymentDate)}
                      </td>
                    </tr>
                    <tr>
                      <th>Note</th>
                      <td>{invoiceData?.note || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <td className="text-uppercase">
                        {invoiceData?.paymentType}
                      </td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td className="text-uppercase">
                        {invoiceData?.paymentStatus}
                      </td>
                    </tr>
                    <tr>
                      <th>Amount</th>
                      <td>₹{invoiceData?.amount} /-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="d-none-md-768p">
              <table className="table text-center">
                <thead className="bg-primary-light-theme">
                  <tr>
                    <th>Payment Date</th>
                    <th className="text-start">Note</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{dateFormatter(invoiceData?.paymentDate)}</td>
                    <td className="text-start">{invoiceData?.note || "NA"}</td>
                    <td className="text-uppercase">
                      {invoiceData?.paymentType}
                    </td>
                    <td className="text-uppercase">
                      {invoiceData?.paymentStatus}
                    </td>
                    <td>₹{invoiceData?.amount} /-</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* <p className="text-start-sm fs-sm-2 mb-4">
              Invoice by :&nbsp;
              {user?.name}&nbsp;({user?.phone})
            </p> */}

            <p className="text-center thank-you-note">
              Thank you for your membership. Be healthy & fit with&nbsp;
              <span className="fw-bold">BS FITNESS FACTORY GYM</span>.
            </p>

            <hr style={{ background: "#dee2e6" }} />

            <p className="fs-sm text-secondary text-center">
              For business software, call 📞&nbsp;
              <span className="text-dark fw-bold">8918569670</span>&nbsp;and
              simplify your business!
            </p>
          </div>
        </>
      )}
      {loader && <p>Generating Invoice .....</p>}

      {!loader && !invoiceData && <h1>{errorMessage}</h1>}
    </div>
  );
};

export default CustomerInvoicePage;
